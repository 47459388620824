import dynamic from 'next/dynamic';

const storePages = {
  celosia: dynamic(() => import('containers/themes/celosia/store')),
  carnation: dynamic(() => import('containers/themes/carnation/store')),
  calathea: dynamic(() => import('containers/themes/calathea/store')),
  lilac: dynamic(() => import('containers/themes/lilac/store')),
  daffodil: dynamic(() => import('containers/themes/daffodil/store')),
  salvia: dynamic(() => import('containers/themes/salvia/store')),
  lupine: dynamic(() => import('containers/themes/lupine/store')),
  custom : dynamic(() => import('containers/themes/custom/store')),
};

export function getStorePage(theme = 'celosia') {
  return storePages[theme] || storePages.celosia;
}
