import React from 'react';
import _, { get } from 'ui/lodash';
import Head from 'next/head';
import { getUserIcon } from 'components/utils/icon';
import PropTypes from 'prop-types';
import { useIsCustomDomain } from 'contexts/profile';

export default function Meta({ shop }) {
  const seo = _.get(shop, `uiConfig.seo`, {});
  const color = get(shop, 'config.themeColor', '#F5603F');
  const isSubscribed = shop.plan !== 'free';
  const isCustomDomain = useIsCustomDomain();

  const showManifest = isSubscribed && isCustomDomain;

  return (
    <Head>
      <meta property="og:title" content={seo?.title || shop.name} key="ogtitle"/>
      <meta property="og:image" content={getUserIcon(shop)} key="ogimage"/>
      <link rel="shortcut icon" href={getUserIcon(shop)}/>
      <title>{seo?.title || shop.name}</title>
      <meta property="description" content={seo?.description || shop.description} key="description"/>
      {seo?.keywords && <meta property="keywords" content={seo?.keywords} key="keywords"/>}
      <meta name="theme-color" content={color}/>
      {showManifest && <link rel="manifest" href="/manifest.json"/>}
    </Head>
  );
}

Meta.propTypes = {
  shop: PropTypes.object.isRequired
};
