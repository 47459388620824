import dynamic from 'next/dynamic';
import { useThemeName } from 'hooks/shop';

const footers = {
  common: dynamic(() => import(`components/store/CommonFooter`)),
  salvia: dynamic(() => import(`components/store/salvia/Footer`)),
  lupine: dynamic(() => import(`components/store/lupine/Footer`)),
};

export function Footer({ shop }) {
  const theme = useThemeName(shop);

  const FooterComponent = footers[theme] || footers.common;

  return (
    <FooterComponent shop={shop}/>
  );
}
