import PropTypes from 'prop-types';
import { CustomError } from 'components/error/Error';
import { getStorePage } from 'containers/themes';
import { Footer } from 'components/store/Footer';
import { useSyncShop, useThemeName } from 'hooks/shop';
import { LocalCartProvider } from 'providers/localCart';
import { useScrollToHash } from 'hooks/scroll';
import Schema from 'components/seo/Meta';
import StoreSeoSchema from 'components/seo/StoreSeoSchema';
import Head from 'next/head';
import { useTrackGa } from 'hooks/ga';

export default function Store({
  shop,
  savedFeed,
  errorCode,
}) {

  useSyncShop(shop);
  useScrollToHash();
  useTrackGa(shop._id, 'shop', shop._id);

  const themeName = useThemeName(shop);

  const Theme = getStorePage(themeName);

  const liveCatalogs = shop?.catalogs;

  const catalogList = liveCatalogs?.map(x => ({
    label: x.caption ? x.caption : '#',
    value: x.caption,
    img: x.url
  }));


  if (errorCode) {
    return <CustomError code={errorCode}/>;
  }


  return (
    <>
      <LocalCartProvider shop={shop}>
        <Schema shop={shop}/>
        <Head>
          <StoreSeoSchema shop={shop}/>
        </Head>
        <Theme
          shop={shop}
          savedFeed={savedFeed}
          templates={shop.templates}
          catalogList={catalogList}
        />
        <div id="shopScroll4"/>
        {themeName !== 'lupine' ? <Footer shop={shop} /> : null }
        
      </LocalCartProvider>
    </>
  );
}

Store.defaultProps = {
  savedFeed: [],
  errorCode: null,
};

Store.propTypes = {
  shop: PropTypes.object.isRequired,
  savedFeed: PropTypes.array,
  errorCode: PropTypes.number,
};
