import React from 'react';
import PropTypes from 'prop-types';
import JsonSchema from 'components/seo/JsonSchema';
import { useCustomDomain, useIsCustomDomain } from 'contexts/profile';
import { getShopIcon } from 'components/utils/icon';

function StoreSeoSchema({
  shop
}) {
  const isCustomDomain = useIsCustomDomain();
  const domain = useCustomDomain();
  const url = isCustomDomain ? `https://${domain}` :
    `https://mywindo.shop/${shop.slug}`
  const storeSchema = {
    '@context': 'https://schema.org',
    '@type': 'Store',
    'name': shop.name,
    'location': shop.country,
    'url': url,
    'logo': getShopIcon(shop?.icon?.[0]),
    'description': shop.description,
    'identifier': shop._id,
  };

  if (shop?.footerInfo?.store?.email) {
    storeSchema.email = shop.footerInfo.store.email;
  }

  if (shop?.footerInfo?.store?.phone) {
    storeSchema.telephone = shop.footerInfo.store.phone;
  }

  if (shop?.footerInfo?.store?.address) {
    storeSchema.address = shop.footerInfo.store.address;
  }

  return (<>
    <JsonSchema schema={storeSchema}/>
  </>);
}

StoreSeoSchema.propTypes = {
  shop: PropTypes.object.isRequired
};

export default StoreSeoSchema;
